import { Routes, Route, } from 'react-router-dom';
import Main from './pages/main/main';
import Review from './pages/review/review';
import Header from './elements/header/header';
import Footer from './elements/footer/footer';
import Contact from './pages/contact/contact';
import Loading from './elements/loading/loading';
import Pay from './pages/pay/pay';
import Tarifs from './pages/tarifs/tarifs';
import ButtonWidget from './elements/widget/buttonWidget';
import 'aos/dist/aos.css'
import URL from './env';




function App() {
  return (
    <>
      {/* <ButtonWidget /> */}
      <Header />
      <Loading />
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/reviews' element={<Review />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/pay' element={<Pay />} />
        <Route path='/service' element={<Tarifs />} />
        <Route path='/*' element={<p>not found</p>} />
      </Routes>
      <Footer />
    </>
  );
}

const noop = () => { }
console.warn = noop; // Отключает console.warn
console.error = noop; // Отключает console.error

export default App;
